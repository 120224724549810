.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Filter Styles */
.mobile-filter {
  display: none;
}

.mobile-filter-toggle {
  /*width: 100%;*/
  padding: 0 10px;
  background-color: #f0f0f0;
  border: none;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 25px;
}

.mobile-filter-content {
  /*background-color: #fff;*/
  padding: 0px;
  /*border: 1px solid #ddd;*/
}

@media (max-width: 768px) {
  .mobile-filter {
    display: block;
    padding: 0px;
  }

  .desktop-filters {
    display: none;
  }

  .catalogue {
    width: 100%;
  }

  .fa-sliders {
    padding: 5px
  }
}
