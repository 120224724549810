body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #01cb01;
}

.back-black {
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-title, .card-text {
    text-align: left;
}

.catalogue-image {
    /*max-width: 20rem;*/
    /*max-height: 15rem;*/
    width: 100%;
    /*height: 100%;*/
    height: 200px;
    object-fit: cover;
    align-items: center;
}

.buy-button {
    text-align: right;
    /*#00c201*/
}

.buy-button a {
    width: 100%;
    text-align: center;
}

.cat-row {
    margin: 10px 0
}

.navbar {
    background-color: #ffffff;
}

.navbar > * {
    text-decoration-color: black;
}

.filters {
    background-color: white;
    margin: 10px 0;
    border-radius: 8px;;
}

.text-left {
    text-align: left;
}

.navbar-brand > img {
    height: 70px
}

.text-right {
    text-align: right;
}

img {
    color: #fff
}

.filter-header {
    background-color: black;
    color: white;
    overflow: hidden;
    margin: 0;
    border-radius: 8px 8px 0 0;
    text-align: left;
}

input {
    padding: 0
}

.filter-label {
    padding-left: 5px;
    padding-right: 5px;
}

.filter-label > label {
    width: 100%
}

.filter-body {
    padding: 10px
}

.page-body {
    padding-top: 100px
}

.card-body {
    border-top: 1px solid #01cb01;
    /*background-color: #56b849;*/
    background-color: #839959;
    color: white;
}

.card {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
}

.card > .favourite {
    float: left;
}

.favourite {
    border: 1px solid #e94b6b;
    border-radius: 12px;
    position: absolute;
    width: 25px;
    height: 25px;
    color: #e94b6b;
    margin: 5px 5px 0 0;
    right: 0
}

.whatsapp {
    position: absolute;
    width: 25px;
    height: 25px;
    color: #40c351;
    margin: 0;
    left: 5px
}

.whatsapp > a, .nav-item > a {
    text-decoration: none; /* no underline */
}

.call-link {
    padding: 10px;
    border-radius: 20px;
}


@media (max-width: 850px) {
    .filters-list {
        width: 100%;
        display: none;
    }

    .catalogue {
        width: 100%;
        margin-top: 5px
    }

    .filters {
        background-color: white;
        margin-bottom: 10px;
        margin-top: 0;
        border-radius: 8px;;
    }
}

@media (max-width: 500px) {
    .catalogue-item {
        width: 100%;
        padding-bottom: 20px
    }

    .cat-row {
        margin: 0
    }

    .navbar-brand > img {
        height: 30px;
    }

    .navbar-brand {
        width: 100%
    }

    .page-body {
        padding-top: 110px
    }
}


@media (max-width: 360px) {
    .nav-item {
        font-size: 0.8rem;
    }

    .page-body {
        padding-top: 100px
    }
}

.filter-item {
    background-color: #01cb01;
    border-radius: 10px;
    margin: 0 0 0 5px;
}

.filter-item > .text-right {
    padding: 0
}

.filters-applied {
    display: flex;
    margin: 0 20px 0 20px
}

.filter-app-item {
    background: white;
    margin: 5px;
    border-radius: 10px;
    padding: 0 5px 0 5px;
}

.close {
    margin: 0 5px 0 5px;
}

.no-items {
    background: white;
    text-align: center;
    padding: 30px 0;
    border-radius: 10px;
}

.fa-square-whatsapp {
    color: #40c351;
}

.fa-facebook {
    color: #0866ff
}

.fa-instagram {
    background: radial-gradient(circle at 30% 110%,
    #ffdb8b 0%,
    #ee653d 25%,
    #d42e81 50%,
    #a237b6 75%,
    #3e57bc 100%);
    border-radius: 15px;
    padding: 2px 5px;
    box-shadow: 0px 15px 40px 1px rgba(0, 0, 0, 0.5);
    color: white;
}

.fa-square-x-twitter {
    color: black
}

.fa-brands {
    transition: .2s linear;
}

.fa-brands:hover {
    transform: translateY(-10px);
}

.fa-square-phone {
    color: #3bda57
}

.fa-tiktok {
    color: black
}

.call-num {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    position: absolute;
}

.call-num:hover {
    text-decoration: #3bda57;
}

/*[title] {*/
/*  display: block;*/
/*  margin: 0 0 1em 0;*/
/*}*/